import { Box, CircularProgress } from '@mui/material';
import styles from './Spinner.module.css';

export const Spinner = () => {
  return (
    <Box className={styles.spinnerContainer}>
      <CircularProgress />
    </Box>
  );
};
