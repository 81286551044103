import { Box, Drawer, Typography } from '@mui/material';
import styles from './Drawer.module.css';

const DrawerComponent = (props) => {
  const { open, onClose, children, anchor = 'right' } = props;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      classes={{ paper: styles.paper }}
    >
      <Box className={styles.header}>
        <Typography variant="h5"> Filings summary</Typography>
      </Box>
      <Box>{children}</Box>
    </Drawer>
  );
};

export default DrawerComponent;
