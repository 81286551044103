import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './Markdown.module.css';

const Markdown = ({ content }) => {
  return (
    <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
      {content}
    </ReactMarkdown>
  );
};

export default Markdown;
