import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/appSlice';
import Spinner from './Spinner';

export const withAuth = (Component) => {
  return function AuthenticatedComponent(props) {
    const user = useSelector(selectUser);
    const router = useRouter();
    const [showSpinner, setShowSpinner] = useState(user.isLoading);

    useEffect(() => {
      if (!user.isLoading) {
        if (user.isValid) {
          setShowSpinner(false);
        } else {
          router.push('/login');
        }
      }
    }, [user, router]);

    if (showSpinner) {
      return <Spinner />;
    }

    return <Component {...props} />;
  };
};
