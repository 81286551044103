import React, { useState, useEffect, useRef, useCallback } from 'react';
import FinancialChart from './FinancialChart';
import { getStockData } from '@/lib/stock';
import { convertAlpacaDataToFinancialChartData } from '@/lib/utils';
import { Box, Divider } from '@mui/material';
import MoreMenu from './MoreMenu';
import { CONFIG_TYPES } from '@/lib/utils/constants';
import { subscribeForTrades } from '@/lib/stream';
import { useSelector } from 'react-redux';
import { selectStreamTrades } from 'store/streamSlice';
import _ from 'lodash';
import { LightChart } from '../LightChart/Chart';

const ChartWrapper = ({ config = {} }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const updateOptions = (updatedOptions) => {
    setChartOptions((options) => ({
      ...options,
      ...updatedOptions,
    }));
  };

  const updateChartConfig = (payload) => {
    const { type } = payload;

    switch (type) {
      case CONFIG_TYPES.NEXT_DAY:
        break;
      case CONFIG_TYPES.PREVIOUS_DAY:
        break;
    }
  };

  const fetchData = async () => {
    try {
      const res = await getStockData(config);

      const _rawConvertedData = convertAlpacaDataToFinancialChartData(res);

      const finalData = [];

      for (let i = 0; i < _rawConvertedData.length - 1; i++) {
        const current = _rawConvertedData[i];
        const next = _rawConvertedData[i + 1];
        const currentTimeInMs = new Date(current.date).getTime();
        const nextTimeInMs = new Date(next.date).getTime();

        if (
          nextTimeInMs - currentTimeInMs === 60 * 1000 ||
          current.volume < 5000
        ) {
          finalData.push(current);
        } else {
          let newCurrent = {
            ...current,
          };

          let newCurrentTimeInMs = new Date(newCurrent.date).getTime();

          while (nextTimeInMs - newCurrentTimeInMs !== 60 * 1000) {
            finalData.push(newCurrent);

            newCurrent = {
              ...newCurrent,
              open: newCurrent.close,
              high: newCurrent.close,
              low: newCurrent.close,
              close: newCurrent.close,
              volume: 0,
            };

            newCurrentTimeInMs = new Date(newCurrent.date).getTime();
          }
          finalData.push(newCurrent);
        }

        if (i === _rawConvertedData.length - 2) {
          finalData.push(_rawConvertedData[_rawConvertedData.length - 1]);
        }
      }

      setChartData({
        results: finalData,
        lightChartResults: finalData.map(
          ({ open, high, low, close, volume, date }) => ({
            open,
            close,
            high,
            low,
            time: date.getTime() / 1000,
          })
        ),
        hasResults: true,
        symbol: config.symbol,
      });
    } catch (err) {
      setChartData({
        results: [],
        lightChartResults: [],
        hasResults: false,
        symbol: config.symbol,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(config)]);

  return (
    <Box
      sx={{
        flex: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <MoreMenu
          updateOptions={updateOptions}
          updateChartConfig={updateChartConfig}
          refreshData={fetchData}
        />
      </Box>
      <Divider />
      <Box sx={{ flex: 1, position: 'relative' }}>
        {chartData?.hasResults ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '90%',
              width: '100%',
              flex: 1,
              position: 'absolute',
              top: 0,
            }}
          >
            Selected stock with data
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '90%',
              width: '100%',
              flex: 1,
              position: 'absolute',
              top: 0,
            }}
          >
            Select a Stock to see the chart
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChartWrapper;
