import React, { useState, useRef, useEffect } from 'react';
import styles from './Index.module.css';
import { Box, Card } from '@mui/material';
import StockEntry from 'widgets/StockEntry';
import {
  getHighVolumeDailyBars,
  getStockFundamentals,
  getStockFilings,
} from '@/lib/stock';
import {
  getAfterHoursCloseDateTime,
  getPremarketOpenDateTime,
  processDailyBarData,
} from '@/lib/utils';
import ChartWrapper from '@/components/Chart/ChartWrapper';
import LightChartWrapper from '@/components/Chart/LightChartWrapper';
import RealTimeGainersAndLosers from 'widgets/RealTimeGainersAndLosers';
import RealTimeHalts from 'widgets/RealTimeHalts';
import Fundamentals from 'widgets/Fundamentals';
import Watchlist from 'widgets/Watchlist';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import History from 'widgets/History';
import FilingsSummary from 'widgets/FilingsSummary';
import DrawerComponent from '@/components/Drawer';
import MomentumScanner from 'widgets/MomentumScanner';
import { TIMEFRAMES } from '@/lib/utils/constants';

const HomeIndex = () => {
  const averageData = useRef({});
  const chartContainerRef = useRef(null);
  const lightChartContainerRef = useRef(null);
  const [selectedStock, setSelectedStock] = useState('');
  const [chartConfig, setChartConfig] = useState({});
  const [fundamentals, setFundamentals] = useState({ averageData: {} });
  const [filings, setFilings] = useState({ data: {} });
  const [openFilings, setOpenFilings] = useState(false);

  const handleFilingsSummaryDrawerClose = () => {
    setOpenFilings(false);
  };

  const openFilingsSummary = (symbol) => {
    setSelectedStock(symbol);
    setOpenFilings(true);
  };

  const scrollChartIntoView = () => {
    chartContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchHistory = async (symbol) => {
    try {
      const data = await getHighVolumeDailyBars(symbol);
      const { averageData: _averageData } = processDailyBarData(data);
      averageData.current = _averageData;
    } catch (err) {
      console.log('Error fetching history', err);
    }
  };

  const fetchFundamentals = async (symbol) => {
    try {
      const { data } = await getStockFundamentals(symbol);
      setFundamentals({ ...data, ...averageData.current });
    } catch (err) {
      setFundamentals({ averageData });
    }
  };

  const fetchFilings = async (symbol) => {
    try {
      const { data } = await getStockFilings(symbol);
      setFilings(data);
    } catch (err) {
      setFilings({ averageData: {} });
    }
  };

  const fetchHistoryAndFundamentals = async (symbol) => {
    if (symbol) {
      setSelectedStock(symbol);
      fetchHistory(symbol);
      fetchFundamentals(symbol);
      // fetchFilings(symbol);
    } else {
      averageData.current = {};
      setFundamentals({ averageData: {} });
      setFilings({ data: {} });
    }
  };

  const onRowClick = async (data) => {
    if (data.Symbol) {
      const payload = {
        from: getPremarketOpenDateTime(data.TimeStamp || new Date()),
        to: getAfterHoursCloseDateTime(data.TimeStamp || new Date()),
        symbol: data.Symbol,
        timeframe: TIMEFRAMES.MINS_1,
      };
      fetchHistoryAndFundamentals(data.Symbol);
      setChartConfig(payload);
    }
    // scrollChartIntoView();
  };

  useEffect(() => {
    setFundamentals((_fundamentals) => {
      return {
        ..._fundamentals,
        ...averageData.current,
      };
    });
  }, [averageData.current]);

  return (
    <Box>
      <Breadcrumbs
        data={[
          {
            label: 'Home',
            link: '',
            disabled: true,
          },
        ]}
      />

      <Box className={styles.container}>
        <Card className={styles.watchlist}>
          <Watchlist onRowClick={onRowClick} />
        </Card>
        <Card className={styles.gainers}>
          <RealTimeGainersAndLosers onRowClick={onRowClick} />
        </Card>
        <Card className={styles.halts}>
          <RealTimeHalts onRowClick={onRowClick} />
        </Card>
        <Card className={styles.momentumSummary}>
          <MomentumScanner onRowClick={onRowClick} />
        </Card>
        <Card ref={chartContainerRef} className={styles.chart}>
          <ChartWrapper
            config={chartConfig}
            setChartConfig={setChartConfig}
            lightChart
          />
        </Card>
        <Card className={styles.stockEntry}>
          <StockEntry
            onStockEntryClick={fetchHistoryAndFundamentals}
            fetchFilingsSummary={openFilingsSummary}
            defaultSymbol={selectedStock}
          />
        </Card>
        <Card className={styles.fundamentals}>
          <Fundamentals fundamentals={fundamentals} />
        </Card>
        <Card className={styles.historyTable}>
          <History symbol={selectedStock} onRowClick={onRowClick} />
        </Card>
        {/* <Card ref={lightChartContainerRef} className={styles.lightChart}>
          <LightChartWrapper config={chartConfig} setChartConfig={() => {}} />
        </Card> */}

        <DrawerComponent
          open={openFilings}
          onClose={handleFilingsSummaryDrawerClose}
          anchor="right"
        >
          <FilingsSummary symbol={selectedStock} />
        </DrawerComponent>
      </Box>
    </Box>
  );
};

export default HomeIndex;
