import { fetcher } from '../fetch';
import { processedPayload } from '../utils';

export const createWatchlist = async (symbols = [], title = 'Untitled') => {
  return await fetcher('/api/watchlists', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ symbols, title }),
  });
};

export const updateWatchlist = async (id, payload) => {
  return await fetcher(`/api/watchlists/${id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: processedPayload({ ...payload }),
  });
};

export const getWatchlists = async () => {
  return await fetcher(`/api/watchlists`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const deleteWatchlist = async (watchlistId) => {
  return await fetcher(`/api/watchlists/${watchlistId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
};
