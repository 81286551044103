import {
  Box,
  Button,
  Chip,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import styles from './FilingsSummary.module.css';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab';
import { useEffect, useRef, useState } from 'react';
import { getStockFilings } from '@/lib/stock';
import { dateMMDDYYY } from '@/lib/utils';
import Markdown from '@/components/Markdown';
import { getFilingSummary } from '@/lib/ai';
import Shimmer from '@/components/Shimmer';
import {
  ALL,
  FILING_TYPES,
  FILINGS_CATEGORIES,
  OTHERS,
} from '@/lib/utils/constants';

const FilingsSummary = ({ symbol }) => {
  const [filings, setFilings] = useState([]);
  const filingsRef = useRef();
  const [selectedCategories, setSelectedCategories] = useState(['all']);

  const fetchFilings = async () => {
    const { data = {} } = await getStockFilings(symbol);
    const { filings = [] } = data;
    const filingTypesHash = Object.values(FILING_TYPES).reduce((acc, curr) => {
      acc[curr.form] = curr;

      return acc;
    }, {});

    const processedFilings = filings.map((f) => {
      const filingTypeInfo = filingTypesHash[f.form?.toUpperCase()] || {
        description: '',
        tags: [],
      };
      return {
        ...f,
        acceptanceDateTime: dateMMDDYYY(f.acceptanceDateTime),
        formDescription: filingTypeInfo.description || '',
        tags: filingTypeInfo.tags || [],
      };
    });

    filingsRef.current = processedFilings;
    setFilings(processedFilings);
  };

  const setFilingSummaryFetchInProgress = (accessionNumber, status) => {
    const updatedFilings = filings.map((f) => {
      if (f.accessionNumber === accessionNumber) {
        return {
          ...f,
          summaryFetchInProgress: true,
        };
      }

      return f;
    });

    filingsRef.current = updatedFilings;

    setFilings(updatedFilings);
  };

  const fetchSummary = async (accessionNumber, url) => {
    setFilingSummaryFetchInProgress(accessionNumber, true);
    let summary = '';

    try {
      const summaryRes = await getFilingSummary(accessionNumber, url);
      summary = summaryRes.summary;
    } catch (err) {
      summary = '';
    }

    const updatedFilings = filings.map((f) => {
      if (f.accessionNumber === accessionNumber) {
        return {
          ...f,
          summaryAvailable: !!summary,
          summary,
          summaryFetchInProgress: false,
        };
      }

      return f;
    });

    filingsRef.current = updatedFilings;

    setFilings(updatedFilings);
  };

  useEffect(() => {
    if (symbol) {
      fetchFilings(symbol);
    }
  }, [symbol]);

  const fetchFilingsBasedOnSelectedCatoegories = (categories) => {
    let extraTypes = [];
    const selectedFilings = FILINGS_CATEGORIES.reduce(
      (acc, { value, types = [] }) => {
        if (categories.includes(value)) {
          acc = [...acc, ...types];
        }
        return acc;
      },
      []
    ).map(({ form }) => form);

    if (categories.includes(OTHERS)) {
      extraTypes = filingsRef.current
        .filter(
          ({ form }) => !selectedFilings.includes(form?.toUpperCase() || '')
        )
        .map(({ form }) => form?.toUpperCase());
    }

    return filingsRef.current.filter(
      ({ form }) =>
        selectedFilings.includes(form?.toUpperCase()) ||
        extraTypes.includes(form?.toUpperCase())
    );
  };

  const handleCategoriesChange = (evt, newCategories) => {
    setSelectedCategories(newCategories);
    if (newCategories.includes(ALL)) {
      setFilings(filingsRef.current);
    } else {
      setFilings(fetchFilingsBasedOnSelectedCatoegories(newCategories));
    }
  };

  return (
    <Box>
      <Box sx={{ margin: '1rem' }}>
        <ToggleButtonGroup
          value={selectedCategories}
          onChange={handleCategoriesChange}
          aria-label="Filing categories"
          classes={{ root: styles.buttonGroup }}
        >
          {FILINGS_CATEGORIES.map(({ name, value }) => (
            <ToggleButton
              value={value}
              aria-label="bold"
              classes={{
                selected: styles.toggleButtonSelected,
                root: styles.toggleButtonRoot,
              }}
            >
              {name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {filings.map((filing) => (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot classes={{ root: styles.dotRoot }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <Typography>{filing.acceptanceDateTime}</Typography>
                  <Link
                    className={styles.link}
                    href={filing.urlPrimaryDocument}
                    target="_blank"
                  >
                    {filing.form || filing.primaryDocDescription}
                  </Link>
                  <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                    {filing.tags?.map((tag) => (
                      <Chip
                        className={styles.chipRoot}
                        label={tag}
                        color="primary"
                        size="small"
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() =>
                        fetchSummary(filing.accessionNumber, filing.url)
                      }
                      disabled={
                        filing.summaryFetchInProgress || filing.summaryAvailable
                      }
                      variant="outlined"
                    >
                      Summarize
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ margin: '0 0 0.5rem 0' }}>
                  {filing.formDescription}
                </Box>
              </Box>
              {filing.summaryFetchInProgress ? (
                <Box className={styles.summaryLoaderContainer}>
                  <Shimmer />
                </Box>
              ) : (
                filing.summaryAvailable && (
                  <Box className={styles.markdownContainer}>
                    <Markdown content={filing.summary} />
                  </Box>
                )
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default FilingsSummary;
