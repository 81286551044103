import { useEffect, useRef, useState } from 'react';
import { EnhancedTable } from '@/components/Table';
import { stockFieldsConfig } from 'config/fields';
import { useSelector } from 'react-redux';
import { selectMomentumStocks } from 'store/streamSlice';
import { COLORS } from '@/lib/utils/constants';

const MomentumScanner = ({ onRowClick = () => {} }) => {
  const [config, setConfig] = useState([]);

  const _onRowClick = (row, index) => {
    onRowClick(row);
  };

  const stocks = useSelector(selectMomentumStocks);

  useEffect(() => {
    setConfig({
      data: stocks,
      columns: [stockFieldsConfig.momentumStockSummary],
      rowBackgroundColor: ({ ClosePrice, OpenPrice }) => {
        return ClosePrice < OpenPrice
          ? COLORS.BACKGROUND_RED
          : COLORS.BACKGROUND_GREEN;
      },
    });
  }, [stocks.length]);

  return (
    <EnhancedTable
      heading="Momentum"
      config={config}
      onRowClick={_onRowClick}
      highlightItems={false}
    />
  );
};

export default MomentumScanner;
