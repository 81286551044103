import React, { useState, useEffect, useRef } from 'react';
import { EnhancedTable } from '@/components/Table';
import { stockFieldsConfig } from 'config/fields';
import {
  createWatchlist,
  getWatchlists,
  updateWatchlist,
} from '@/lib/watchlists';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  selectStreamConnectionStatus,
  selectStreamTrades,
} from 'store/streamSlice';
import { subscribeForTrades, unsubscribeFromTrades } from '@/lib/stream';
import { ACTIONS } from '@/lib/utils/constants';
import { noop } from 'lodash';

const Watchlist = ({ onRowClick }) => {
  const [watchlists, setWatchlists] = useState([{ symbols: [] }]);
  const watchlistRef = useRef([{ symbols: [] }]);
  const [config, setConfig] = useState({});
  const [symbolToBeAdded, setSymbolToBeAdded] = useState('');
  const streamConnected = useSelector(selectStreamConnectionStatus);
  const trades = useSelector(selectStreamTrades);

  const _onChange = (dataIndex, value) => {
    setConfig((_config) => {
      const _data = [..._config.data];
      _data[dataIndex].Symbol = value?.toUpperCase();
      _config.data = _data;
      return { ..._config };
    });
  };

  const _updateWatchlist = async (_data = []) => {
    const [watchlist] = watchlistRef.current;

    try {
      if (!watchlist._id) {
        const watchlist = await createWatchlist(
          _data.map(({ Symbol }) => Symbol).filter((symbol) => !!symbol),
          'Watchlist'
        );

        setWatchlists([watchlist]);
      } else {
        updateWatchlist(watchlist._id, {
          ...watchlist,
          symbols: _data
            .map(({ Symbol }) => Symbol)
            .filter((symbol) => !!symbol),
        });
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const _onSubmit = () => {
    if (event.keyCode == 13) {
      setConfig((_config) => {
        let _data = [..._config.data].map((_d) => ({
          ..._d,
          CurrentPrice: 0,
          inEditMode: !_d.Symbol,
        }));

        if (!_data.find((_d) => !_d.Symbol)) {
          _data.unshift({
            Symbol: '',
            onChange: (dataIndex, value) => _onChange(dataIndex, value),
            onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
            inEditMode: true,
            onClick: noop,
          });
        }

        _config.data = _data;

        _updateWatchlist(_data);
        return { ..._config };
      });
    }
  };

  const _onClick = (dataIndex) => {
    setConfig((_config) => {
      _config.data[dataIndex].inEditMode = true;
      return _config;
    });
  };

  const fetchWatchlists = async () => {
    const { watchlists: _watchlists } = await getWatchlists();
    setConfig({
      data: [
        {
          Symbol: '',
          CurrentPrice: 0,
          onChange: (dataIndex, value) => _onChange(dataIndex, value),
          onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
          inEditMode: true,
          onClick: noop,
        },
        ...(_watchlists[0]?.symbols || []).map((symbol) => ({
          Symbol: symbol,
          CurrentPrice: 0,
          onChange: (dataIndex, value) => _onChange(dataIndex, value),
          onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
          inEditMode: false,
          onClick: (dataIndex, value) => _onClick(dataIndex, value),
        })),
      ],
      columns: [
        stockFieldsConfig.actions.delete,
        stockFieldsConfig.enrichedEditableSymbolWithMovement,
        stockFieldsConfig.Percentage,
        stockFieldsConfig.CurrentPrice,
        stockFieldsConfig.Volume,
      ],
    });
    setWatchlists(_watchlists);
  };

  const updateStreamSubscriptions = async (watchlists) => {
    const [watchlist] = watchlists;

    const symbols = watchlist.symbols;
    // await streamConnected;
    symbols?.length && subscribeForTrades(symbols);
  };

  const handleDelete = (row, index) => {
    setConfig((_config) => {
      _config.data = _config.data.filter((val, ind) => ind !== index);
      _updateWatchlist(_config.data);
      row.Symbol && unsubscribeFromTrades([row.Symbol]);

      return _config;
    });
  };

  const handleAdd = (event) => {
    if (event.keyCode == 13) {
      setConfig((_config) => {
        const currentList = _config.data;
        currentList.push({
          Symbol: symbolToBeAdded,
          CurrentPrice: 0,
          onChange: (dataIndex, value) => _onChange(dataIndex, value),
          onSubmit: (dataIndex, value) => _onSubmit(dataIndex, value),
          inEditMode: false,
          onClick: (dataIndex, value) => _onClick(dataIndex, value),
        });

        _config.data = currentList;
        _updateWatchlist(currentList);
        subscribeForTrades([symbolToBeAdded]);

        return _config;
      });
    }
  };

  useEffect(() => {
    // Fetch watchlists
    fetchWatchlists();
  }, []);

  useEffect(() => {
    watchlistRef.current = watchlists;

    updateStreamSubscriptions(watchlists);
  }, [JSON.stringify(watchlists)]);

  useEffect(() => {
    if (Object.keys(trades).length) {
      setConfig((_config) => {
        let _data = [...(_config.data || [])].map((_d) => ({
          ..._d,
          CurrentPrice: trades[_d.Symbol],
        }));

        _config.data = _data;
        return { ..._config };
      });
    }
  }, [trades]);

  return (
    // <Box>
    //   <TextField
    //     placeholder="Symbol"
    //     size="small"
    //     onChange={(event) =>
    //       setSymbolToBeAdded(event.target.value.toUpperCase())
    //     }
    //     onKeyDown={(event) => handleAdd(event)}
    //     value={symbolToBeAdded}
    //     sx={{ width: 100 }}
    //     onBlur={() =>
    //       handleAdd({
    //         keyCode: 13,
    //       })
    //     }
    //   />
    <EnhancedTable
      config={config}
      onRowClick={onRowClick}
      actions={{
        [ACTIONS.DELETE]: handleDelete,
      }}
    />
    // </Box>
  );
};

export default Watchlist;
