import { Box, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import Link from 'next/link';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ data = [] }) => {
  return (
    <Box sx={{ marginLeft: 1.5, marginBottom: 1.5 }}>
      <MUIBreadcrumbs
        separator={
          <Box
            sx={{
              backgroundColor: 'var(--success)',
              height: 4,
              width: 4,
              borderRadius: '50%',
            }}
          />
        }
        sx={{ fontSize: 12 }}
      >
        {data.map(({ link, label, disabled }) =>
          !disabled ? (
            <Link
              key={link + label}
              href={link}
              passHref
              className={styles.link}
            >
              {label}
            </Link>
          ) : (
            <span key={link + label}>{label}</span>
          )
        )}
      </MUIBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
