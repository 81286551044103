import React from 'react';
import styles from './Shimmer.module.css';

const Shimmer = ({
  width = '100%',
  height = '100%',
  borderRadius = '4px',
  duration = '1.5s',
}) => {
  return (
    <div
      className={styles.shimmer}
      style={{
        width,
        height,
        borderRadius,
        animationDuration: duration,
      }}
    />
  );
};

export default Shimmer;
